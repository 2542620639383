import { useAnimations } from "../effects/Effects"

export const Introduction = () => {

    const {
        handleClickGoServices
    } = useAnimations()

    return (


        <div className='introduction__container' id="home">

            <button
                className='introduction__scroll-container'
                onClick={handleClickGoServices}
            >

                <h2>
                    <span>D</span>
                    <span>E</span>
                    <span>S</span>
                    <span>L</span>
                    <span>I</span>
                    <span>Z</span>
                    <span>Á</span>
                </h2>

                <div className="introduction__scroll-arrow">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

            </button>

            <div className='introduction__rights-container' >

                <h2>{new Date().getFullYear()} © FAETANI TECHOS</h2>

                <h3>Todos los derechos reservados</h3>

            </div>

            <div className='introduction__info' >

                <h1>+ 40 AÑOS DE TRAYECTORIA<br />COMPROMISO Y CALIDAD</h1>

            </div>

        </div>

    )
}
