import { useLogEvent } from "../hooks/useLogEvent"

interface Props {
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const About = ({ setShowModal }: Props) => {

    const { handleLogEvent } = useLogEvent()

    const json = {
        title: "Faetani Techos es una empresa Argentina de servicios y construcción de techos con una amplia trayectoria en el mercado de nuestro país.",
        data: [
            `Tiene su origen en 1954, cuando Eugenio, Salvador y José Faetani, fundan Faetani hnos,  iniciando así su trayecto empresarial en el mundo de la construcción. 
            Los hermanos habían sido previamente formados y capacitados como Capataces y encargados en la empresa Amiel & Cía, una antigua y tradicional empresa especializada 
            en techos, la cual contaba por ejemplo entre sus obras realizadas con la mansarda del casino de Mar del Plata.`,
            `Tras varios años de éxito y crecimiento sostenidos, Faetani hnos. construye entre otras obras, en 1968, la cubierta de cobre del antiguo casco de estancia de la 
            Cabaña de cría Holando-Argentina, donde hoy se ubica El Club House principal del exclusivo Country Club Martindale.`,
            `En 1986 Faetani hnos. ofrece formar parte de la sociedad al mayor de la siguiente generación de techistas, E. Hector Faetani, quien se desempeñaba como encargado 
            de obras en la Empresa y ya había incursionado realizando obras por su cuenta desde 1983, por lo que agradece a su padre y tíos la oferta, pero prefiere fundar 
            con su anuencia e incondicional apoyo técnico la Empresa FAETANI TECHOS.`,
            `Comprometido con el progreso y desarrollo de la industria nacional, a partir de ese momento y capitalizando el bagaje de experiencia acumulado durante años por 
            la generación anterior, Hector decide evolucionar hacia un modelo más amplio y eficaz de producción, capacitándose aún más en los procesos de restauración de 
            cubiertas antiguas, estructuras complejas de madera, y todos los tipos de cubiertas antiguas y actuales existentes en el mercado.`,
            `Crea vínculos comerciales y técnicos con canteras de extracción de Pizarra natural de la zona de Castilla y León, España. Del mismo modo incursiona y viaja a los 
            obrajes Misioneros para conocer desde adentro los procesos primarios de extracción de la madera, sus tipos y características.`,
            `En 2018 se suma a la empresa la tercera generación, el joven Eugenio Faetani, quien le da un nuevo ímpetu a la empresa participando activamente en distintas 
            restauraciones y el mantenimiento de todas las cubiertas (37 hectáreas), de la planta Caseros de Peugeot Citroën Arg y Stellantis SA.`,
            `Nuestro crecimiento ininterrumpido a través de los conocimientos y valores pasados de generación tras generación es motivo de orgullo y deseo de continuar siendo 
            una empresa constructora líder de servicios, que brinde a nuestros clientes proyectos sustentables, seguros, innovadores y de alto valor agregado.`,
            `Prueba de ello son las numerosas recomendaciones recibidas de profesionales, clientes particulares y proveedores de este segmento de mercado, resaltando siempre 
            la calidad de ejecución, excelencia, competitividad, compromiso y cumplimiento contractual.`
        ]
    }

    return (

        <div className='about__container' >

            <div className="about__id-target" id="about" />

            <div className='about__content-container'>

                <div className='about__title-container utils__fade-in'>

                    <hr />

                    <h1>Quienes somos</h1>

                    <hr />

                </div>

                <div className='about__subtitle-container utils__fade-in'>

                    <h1>Faetani Techos es una empresa Argentina de servicios y construcción de techos con una amplia trayectoria en el mercado de nuestro país.</h1>

                </div>

                <div className='about__info'>

                    <div className='about__image utils__fade-in' />

                    <div className='about__history-container'>

                        {
                            json.data.map((value, index) => {
                                return <h3 className='utils__fade-in' id={index.toString()}>{value}</h3>
                            })
                        }

                        <button className='utils__fade-in' onClick={() => {
                            setShowModal(true);

                            handleLogEvent('Click_boton_conocer_mas');
                        }}
                        >
                            CONOCER MÁS
                        </button>

                    </div>

                </div>

            </div>

        </div >

    )
}
