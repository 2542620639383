import {
    BrowserRouter as Router,
    Switch,
    Redirect,
    Route,
} from "react-router-dom";
import { HomeScreen } from '../screens/HomeScreen';
import { ProyectsScreen } from "../screens/ProyectsScreen";
import { ProyectScreen } from "../screens/ProyectScreen";
import ScrollToTop from "../components/ScrollToTop";

export const AppRouter = () => {


    return (

        <Router>

            <div className='router-content'>

                <ScrollToTop />

                <Switch>

                    <Route
                        exact
                        path='/'
                        component={HomeScreen}
                    />

                    <Route
                        exact
                        path='/proyects'
                        component={ProyectsScreen}
                    />

                    <Route
                        exact
                        path='/proyects/:id'
                        component={ProyectScreen}
                    />

                    <Redirect to='/' />

                </Switch>

            </div>

        </Router>

    )
}
