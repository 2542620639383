import { Header } from "../components/Header"
import { Proyects } from "../components/Proyects"
import { WsFloatButton } from "../components/WsFloatButton"

export const ProyectsScreen = () => {

    return (

        <div className='home__main-content'>

            <WsFloatButton />

            <Header />

            <Proyects />

        </div>

    )
}
