import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { initialStateWork, worksList } from "../utils/Jsons";
import { WorkProps } from "../interfaces/WorkInterfaces";
import { useAnimations } from "../effects/Effects";
import { isMobile } from "react-device-detect";

export const Proyect = () => {

    const location = useLocation();

    const history: any = useHistory();

    const [workDetail, setWorkDetail] = useState<WorkProps>(initialStateWork);

    const [animationActive, setAnimationActive] = useState(false);

    const {
        handleClickGoWork,
        handleClickGoLastWorks
    } = useAnimations()

    const handleClick = () => {
        setAnimationActive(true);

        setTimeout(() => {
            setAnimationActive(false);
        }, 1500);
    };

    useEffect(() => {

        const setWork = (pathname: string) => {

            const pathnameId = parseInt(pathname.replace('/proyects/', ''));

            setWorkDetail(worksList.find((value) => { return value.id === pathnameId }) ?? initialStateWork);

        }

        setWork(location.pathname);

        handleClick()

    }, [])

    return (

        <div className='proyect__container' id='workHome'>

            {
                !isMobile && (

                    <button
                        className='proyect__scroll-container'
                        onClick={handleClickGoWork}
                    >

                        <h2>
                            <span>D</span>
                            <span>E</span>
                            <span>S</span>
                            <span>L</span>
                            <span>I</span>
                            <span>Z</span>
                            <span>Á</span>
                        </h2>

                        <div className="proyect__scroll-arrow">
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>

                    </button>

                )
            }

            <button
                className='proyect__scroll-container2'
                onClick={handleClickGoWork}
            >
                <div className="proyect__scroll-arrow">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>

            </button>

            <div
                className='proyect__image'
                style={{
                    background: `url(${workDetail.images[0]}) center no-repeat`,
                }}
            />

            <div className={`proyect__info ${animationActive ? 'active' : ''}`}>

                <button
                    className={`proyect__info-comeback ${animationActive ? 'active' : ''}`}
                    onClick={async () => {

                        if (history.location.state?.returnTo === 'home') {

                            await history.push('/'); await handleClickGoLastWorks()

                        } else {

                            const pathnameId = parseInt(location.pathname.replace('/proyects/', ''));

                            history.push('/proyects', { workIndex: pathnameId - 1 })

                        }

                    }}
                >
                    <div>→</div>

                    <h2>Volver a <span>{history.location.state?.returnTo === 'home' && 'Utimas '}Obras</span></h2>

                </button>

                {/* <h2>Obra</h2> */}

                <h3>Localización: <span>{workDetail.address}</span></h3>

                <h1
                    className={`${workDetail.title.length > 25 && 'proyect__info-big-title'}`}
                >
                    "{workDetail.title}"
                </h1>

                <h3>Cliente: <span>{workDetail.client}</span></h3>



            </div>



        </div>

    )
}
