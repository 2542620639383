import { useEffect, useRef, useState } from "react";
import { WorkProps } from "../interfaces/WorkInterfaces";
import { initialStateWork, worksList } from "../utils/Jsons";
import { useHistory, useLocation } from "react-router-dom";

interface Props {
    showModal: number | null
    setShowModal: React.Dispatch<React.SetStateAction<number | null>>
}

export const WorkModal = ({ showModal, setShowModal }: Props) => {

    const containerRef = useRef(null);

    const history = useHistory();

    const location = useLocation();

    const [visibleImage, setVisibleImage] = useState(showModal ?? 0);

    const [workDetail, setWorkDetail] = useState<WorkProps>(initialStateWork);

    useEffect(() => {

        const setWork = (pathname: string) => {

            const pathnameId = parseInt(pathname.replace('/proyects/', ''));

            if (!worksList.find((value) => { return value.id === pathnameId })) {
                history.push('/proyects')
            }

            setWorkDetail(worksList.find((value) => { return value.id === pathnameId }) ?? initialStateWork);

        }

        setWork(location.pathname);

    }, [location])

    const cambiarImagen = (direction: any) => {

        const totalImagenes = workDetail.images.length;

        let nuevaImagenVisible = visibleImage + direction;

        if (nuevaImagenVisible === -1) {
            nuevaImagenVisible = totalImagenes - 1;
        } else if (nuevaImagenVisible < 1) {
            nuevaImagenVisible = 0;
        } else if (nuevaImagenVisible >= totalImagenes) {
            nuevaImagenVisible = 0;
        }

        setVisibleImage(nuevaImagenVisible);
    };

    const handleEscKeyPress = (event: any) => {
        if (event.key === 'Escape') {
            setShowModal(null)
        }
    };

    useEffect(() => {
        window.addEventListener('keydown', handleEscKeyPress);
        return () => {
            window.removeEventListener('keydown', handleEscKeyPress);
        };
    }, []);

    return (

        <div className='workModal__container' id="modal">

            <button
                onClick={() => {
                    setShowModal(null)
                }}
            >

                <img
                    className='workModal__close-image'
                    src={require('../images/cerrar.webp')}
                    alt='logo'
                    id="cerrarModal"
                />

            </button>

            <div className='proyects__scroll-container'>

                <button className="proyects__scroll-arrow-go-left workModal__scroll-arrow" onClick={() => cambiarImagen(-1)}>
                    <span></span>
                </button>

                <button className="proyects__scroll-arrow-go-right workModal__scroll-arrow" onClick={() => cambiarImagen(1)}>
                    <span></span>
                </button>

            </div>


            <div className='workModal__works-counter' >

                {
                    workDetail.images.map((value, index) => (

                        <div
                            style={{ backgroundColor: index === visibleImage ? 'white' : undefined }}
                            key={value}
                        />

                    ))
                }

            </div>


            <div ref={containerRef} className='workModal__content-container'>

                <img
                    className='workModal__info-container'
                    src={workDetail.images[visibleImage]}
                    alt='logo'
                    id="cerrarModal"
                />

            </div>

        </div >

    )
}
