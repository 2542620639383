import { Services } from "../components/Services"
import { Header } from "../components/Header"
import { Introduction } from "../components/Introduction"
import { WsFloatButton } from "../components/WsFloatButton"
import { Rights } from "../components/Rights"
import { About } from "../components/About"
import { Works } from "../components/Works"
import { Footer } from "../components/Footer"
import { AboutModal } from "../components/AboutModal"
import { useEffect, useState } from "react"
import { scrollFadeIn } from "../effects/Observers"
import { Preloader } from "../components/Preloader"

export const HomeScreen = () => {

    const [showModal, setShowModal] = useState(false);

    const [showPreloader, setShowPreloader] = useState(true);

    const handleClick = () => {

        setShowPreloader(true);

        setTimeout(() => {
            setShowPreloader(false);
        }, 2000);

    };

    useEffect(() => {

        handleClick()

        scrollFadeIn()
    }, [])

    return (

        <div className='home__main-content'>

            {showPreloader && <Preloader />}

            <WsFloatButton />

            <Header />

            <Introduction />

            <Services />

            <About setShowModal={setShowModal} />

            {showModal && <AboutModal showModal={showModal} setShowModal={setShowModal} />}

            <Works />

            <Footer />

            <Rights />

        </div>


    )
}
