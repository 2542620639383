import { WorkProps } from "../interfaces/WorkInterfaces";

export const worksList: WorkProps[] = [
    {
        id: 1,
        event: 'aca_villa_la_angostura',
        title: 'A.C.A. Villa La Angostura',
        address: 'Villa La Angostura',
        // address: 'Ruta Nacional 231 y Av. Arrayanes, Villa La Angostura',
        client: 'INAPCON Empresa Constructora',
        description: `Construcción de cubierta con una elaborada y bella estructura de madera en 
        Pino Oregón chileno, cerchas, cabriadas, aristas, lucarnas y vigas macizas de hasta 12metros 
        de largo conforman el sostén del techo, que en la temporada invernal debe soportar eventualmente, 
        sobrecargas excesivas por el peso de la nieve acumulada . El machimbre colocado es de ¾” de 
        espesor en la misma madera. Las aislaciones hidrófugas utilizadas fueron una capa de ruberoid 
        N°15, más una capa de membrana Tyvek de Dupont , mientras que las aislaciones térmicas estuvieron 
        compuestas por una capa de polietileno expandido de 3” de espesor y Kg. x m3 , más una manta 
        completa de lana de vidrio de 2” con papel Craf.La cubierta es de chapa prepintada trapezoidal 
        color gris sujeto por tornillos auto perforantes de 2 ½”. Los remates de zinguería, con finas 
        terminaciones de babetas y caballetes de cumbrera fueron fabricados en nuestros talleres en 
        Bs. As. Con chapa prepintada lisa Cal.N°24.Pérgolas y deck fueron construidos también, con 
        las nobles y bellas maderas andinas.`,
        images: [
            require('../images/aca-1.webp'),
            require('../images/aca-2.webp'),
            require('../images/aca-3.webp'),
            require('../images/aca-4.webp'),
            require('../images/aca-5.webp'),
        ]
    },
    {
        id: 2,
        event: 'la_prensa',
        title: 'La Prensa',
        address: 'Capital Federal, Bs As',
        // address: 'Av. De Mayo al 550, Capital Federal',
        client: 'CASAGO S.A . Empresa constructora',
        description: `Cambio y colocación de pizarras faltantes y/o rotas, las cuales fueron cortadas 
        a medida en canteras españolas; debido a la inexistencia de esa medida en el mercado actual; 
        fueron sujetas por doble suncho de chapa de cobre, plegado sobre el extremo inferior de las mismas. 
        En las molduras de zinc se hizo un trabajo de restauración en trozos faltantes y/o rotos con resinas 
        naturales y fibra de vidrio, en sucesivas capas, para luego colocarles dos manos de plomina sintético 
        mate.Como tarea final se hidrolavó toda la cubierta a 80 kg. de presión x cm2, dando todos los 
        trabajos antes enunciados un aspecto de rejuvenecimiento aceptable, manteniendo sin excepción las 
        formas y diseños originales.`,
        images: [
            require('../images/prensa-2.webp'),
            require('../images/prensa-1.webp'),
            require('../images/prensa-3.webp'),
        ]
    },
    {
        id: 3,
        event: 'club_san_diego',
        title: 'Club San Diego',
        address: 'Country Club San Diego',
        client: 'Tueroc S.A.',
        description: `Estructura de pino paraná de 3" x 12" en vigas y 3"x 5" en cabios , cabriadas en 3"x6" 
        de pino paraná , torre emblema en mismas escuadrías , machimbrado de ¾" x5" de pino Eliotis, aislación 
        hidrófuga membrana Tyvek , térmica tergopol de 25mm ,zinguería en chapa N*24 y cubierta de tejas 
        francesas color natural. Revestimientos exteriores de virapitá de 1" recubriendo vigas y simulando 
        columnas .Producto logrado de una exelente terminación.`,
        images: [
            require('../images/san-diego-2.webp'),
            require('../images/san-diego-1.webp'),
        ]
    },
    {
        id: 4,
        event: 'estancia_bella_vista',
        title: 'Estancia Bella Vista',
        address: 'Ruta nacional Nº2 Km.168, (14 km. al Este por camino de tierra)',
        client: 'Arq. Ana Pusiol e Ing. Tomás De Caro.',
        description: `Cubierta de pizarras naturales de 0,25cmx 0,50cm ,las cuales para su reposición 
        debieron ser cortadas a medida en España, la zinguería artística se cambió en su totalidad por 
        una de idénticas formas y funciones , mientras que el ático fué recubierto en su interior con 
        machimbre de pinotea entre los cabios de pìnotea de 3"x6" los cuales fueron lijados para que queden 
        a la vista (entre el machimbre colocado nuevo y el entablonado existente , se colocó una aislación 
            térmica de 2" de primera marca). Las galerías perimetrales fueron desarmadas hasta la 
            estructura reemplazando los tirantes en mal estado y colocando un machimbre de pinotea 
            moldurado con formas identicas a las originales, colocando aislaciones hidrófugas y térmicas 
            de primera marca , para luego alistonar y colocar las pizarras grafadas metálicas Tecóm. 
            Todas las molduras de madera y zinguería fueron logradas de manera idénticas a las originales. 
            En las casas de encargado , huéspedes , quincho y carnicería , se desmontó hasta la bobedilla 
            de ladrillos , se colocaron aislaciones térmicas e hidrófugas de primera marca, previo reparar 
            sectores de aleros y otros de madera en mal estado , se colocaron chapas onduladas prepintadas 
            y zinguerías artísticas de borde.`,
        images: [
            require('../images/estancia-bv-1.webp'),
            require('../images/estancia-bv-2.webp'),
        ]
    },
    {
        id: 5,
        event: 'secret_agric_ganad_pesca',
        title: 'Secretaría de Agricultura, Ganadería y Pesca',
        address: 'Paseo Colón, Capital Federal',
        client: 'Casago S.A . Constructora',
        description: `Se removió la totalidad de la cubierta de pizarras de piedra hasta entablonado 
        original, cambiando las zonas de madera en mal estado y tratando la totalidad con una mano de 
        pentacloro fenol. Luego se procedió al rearmado de las pizarras sujetándolas con clavos de cobre 
        y de la zinguería estándar (canaletas ocultas , babetas , ñoques de converzas y de pared), una vez 
        en este punto se procedió a la colocación de la zinguería ornamental (caballetes de cumbrera 
            moldurados, membrones , molduras y ornamentos varios). La restauración de la cubierta se 
            llevó a cabo respetando las reglas del arte de época aplicado a los materiales con los cuales 
            hoy se cuenta.Los trabajos se ejecutaron en forma parcial (por tramos ) para que las 
            instalaciones siguieran funcionando con normalidad).`,
        images: [
            require('../images/secretaria-2.webp'),
            require('../images/secretaria-1.webp'),
            require('../images/secretaria-3.webp'),
            require('../images/secretaria-4.webp'),
            require('../images/secretaria-5.webp'),
        ]
    },
    {
        id: 6,
        event: 'club_portezuelo',
        title: 'Club Portezuelo',
        address: 'Country Nordelta - Benavidez, pcia de Bs. As.',
        client: 'Obras y Sistemas S.A',
        description: `Cubiertas realizadas en chapas sinuzoidales prepintadas, con madera en pino Eliotis 
        tratado con Autoclave aislación térmica de lana de vidrio de 50mm . Trabajo realizado con una 
        excelente terminación, sobre todo en los cielorrasos inclinados de machimbre de ¾" en pino Eliotis 
        con CCA.`,
        images: [
            require('../images/portezuelo-1.webp'),
            require('../images/portezuelo-2.webp'),
            require('../images/portezuelo-3.webp'),
        ]
    },
    {
        id: 7,
        event: 'vivienda_particular',
        title: 'Vivenda particular',
        address: 'San jorge Village',
        client: 'Emilio Maurette & Cia',
        description: `Estructura de madera dura ,entablonado de ½" aislaciones comunes, cobertura de 
        pizarras naturales españolas y terminaciones de excelente calidad.`,
        images: [
            require('../images/particular-4.webp'),
            require('../images/particular-1.webp'),
            require('../images/particular-2.webp'),
            require('../images/particular-3.webp'),
        ]
    },
    {
        id: 8,
        event: 'vivienda_unifamiliar',
        title: 'Vivienda unifamiliar',
        address: 'Complejo Nordelta, Barrio La Isla',
        client: 'Arquitectos Guillermo y Luciano Pestarino',
        description: `Construcción de cubierta a varias aguas con estructura de madera de pino Paraná, 
        con cabios de 3”x5” de espesor y vigas de 3”x10” en la misma madera. Las puntas de cabios en 
        aleros fueron Â trabajadas y maquinadas in situ con la característica forma colonial/mediterránea, 
        el machimbre fue de ½” de pino Eliotis tratado con CCA, aislación hidrófuga de ruberoid Nº 15, 
        una capa Térmica/hidrófuga de TBA5Â y Â térmica compuesta por una capa de polietileno expandido 
        de 20mm x 20kg x m3. Listonería de 1”x2” y 1”x3” respectivamente. Zinguería de techo: converzas, 
        babetas y bandas de terminaciones recortadas con forma de tejas en chapa galvanizada Nº24.
        Tejas coloniales marca Bolla color Siena , sujetas por clavos de cobre.
        Caballetes de cumbreras, limatesas, limahoyas y aristas asentados en mortero con hidrófugo.`,
        images: [
            require('../images/unifamiliar-1.webp'),
            require('../images/unifamiliar-2.webp'),
        ]
    },
    {
        id: 9,
        event: 'casco_de_estancia',
        title: 'Casco de Estancia',
        address: 'Ruta 205, Km 172',
        client: 'Sr Antonio Probaos',
        description: `Reforma de casco de estancia centenario, sobre el techo original de chapa oculto por las 
        cargas perimetrales y descargando el peso de la cubierta sobre las mismas, por medio de sendas vigas 
        reticuladas de hierro, previo cálculo estructural se construyó la mansarda perimetral estilo francés. 
        Se colocaron medias cabriadas de hierro cada 0,60cm y entablonado de 1” de pino Paraná.
        La cubierta fue colocada de pizarras naturales Españolas de 0,20cmx0, 30cm, sujetas por clavos de cobre.
        Se dieron las terminaciones correspondientes colocando caballetes de aristas y rematando en la parte 
        superior con un fino membròn fabricado en nuestros talleres en chapa galvanizada.`,
        images: [
            require('../images/casco-1.webp'),
            require('../images/casco-2.webp'),
            require('../images/casco-3.webp'),
        ]
    },
    {
        id: 10,
        event: 'vivienda_fin_de_semana',
        title: 'Vivienda de fin de semana',
        address: 'Argentino Golf Club',
        client: 'Emilio Maurette & Cia',
        description: `Estructura de madera dura (grapia Cep.) con machimbre de ½"x6" de pino brasil en las 
        partes a la vista, aislación hidrófuga de ruberoid y térmica de tergopol de 20kg x m3. Cubierta de 
        pizarras naturales españolas. Zinguería en chapa N*24, y excelentes terminaciones.`,
        images: [
            require('../images/finde-1.webp'),
            require('../images/finde-2.webp'),
        ]
    },
    {
        id: 11,
        event: 'club_union_trabaj_gastro',
        title: 'Club de la Unión de Trabajadores Gastronómicos',
        address: 'Ruta Nro 9 - Km35, Escobar, pcia. de Bs. As.',
        client: 'CASAGO S.A . Empresa constructora',
        description: `S.U.M :estructura de pino paraná montada sobre cabriadas y correas metálicas, 
        machimbre de ½"eliotis ,hidrófuga ruberoid, térmica tergopol de 16 kg x m3 ,tejas francesas naturales. 
        Pórtico de acceso: Idem anterior, más cielorraso suspendido de machimbre ¾"x6", este trabajo requirió 
        una alta calidad de terminación.`,
        images: [
            require('../images/union-2.webp'),
            require('../images/union-1.webp'),
        ]
    },
    {
        id: 12,
        event: 'casio_kasar_arg',
        title: 'Casio Kasar Arg. S.A',
        address: 'Country Club San Diego',
        client: 'Arq. Raúl Peirás',
        description: `Estructura realizada con multilaminado, machimbre de 3/4"de pino eliotis, aislaciones comunes,
         tejas francesas negras brillantes, el salón central tiene forma exagonal, de 14 m de ancho, sin soporte medio 
         ni encastres metálicos. Este trabajo demandó un esmerado estudio de encastres y cortes, de manera que el peso 
         fuera distribuido en todo el perimetro sin sobredimensionar estructuras, la mano de obra fue de un excelente 
         nivel técnico.`,
        images: [
            require('../images/kasio-2.webp'),
            require('../images/kasio-1.webp'),
        ]
    },
]

export const initialStateWork: WorkProps = {
    id: 0,
    title: '',
    event: '',
    address: '',
    client: '',
    description: '',
    images: []
}
