import { useHistory } from "react-router-dom";
import { useAnimations } from "../effects/Effects";

export const NavButtons = () => {

    const {
        handleClickGoHome,
        handleClickGoServices,
        handleClickGoAbout,
        handleClickGoSocial
    } = useAnimations()

    const history = useHistory();

    const handleGoWorks = () => {

        history.push('/proyects', { workIndex: 0 })
    }

    return (

        <div className={`navbar__container`}>

            <nav className="navbar__buttons">

                <button onClick={async () => { await history.push('/'); await handleClickGoHome() }}>Inicio</button>

                <button onClick={async () => { await history.push('/'); await handleClickGoServices() }}>Servicios</button>

                <button onClick={async () => { await history.push('/'); await handleClickGoAbout() }}>Nosotros</button>

                <button onClick={handleGoWorks}>Obras</button>

                <button onClick={async () => { await history.push('/'); await handleClickGoSocial() }}>Contacto</button>

            </nav>

        </div>

    )
}
