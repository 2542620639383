import { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from "react-router-dom";
import { initialStateWork, worksList } from '../utils/Jsons';
import { WorkProps } from '../interfaces/WorkInterfaces';

interface Props {
    setShowModal: React.Dispatch<React.SetStateAction<number | null>>
}

export const Work = ({ setShowModal }: Props) => {

    const location = useLocation();

    const history = useHistory();

    const [workDetail, setWorkDetail] = useState<WorkProps>(initialStateWork);

    useEffect(() => {

        const setWork = (pathname: string) => {

            const pathnameId = parseInt(pathname.replace('/proyects/', ''));

            if (!worksList.find((value) => { return value.id === pathnameId })) {
                history.push('/proyects')
            }

            setWorkDetail(worksList.find((value) => { return value.id === pathnameId }) ?? initialStateWork);

        }

        setWork(location.pathname);

    }, [location])

    const carouselRef: any = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [dragDistance, setDragDistance] = useState(0);

    // Umbral para distinguir entre un clic y un arrastre
    const CLICK_THRESHOLD = 1;

    const handleMouseDown = (e: any) => {
        setIsDragging(true);
        setStartX(e.pageX - carouselRef.current.offsetLeft);
        setScrollLeft(carouselRef.current.scrollLeft);
        setDragDistance(0);
    };

    const handleMouseMove = (e: any) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - carouselRef.current.offsetLeft;
        const walk = (x - startX) * 1;
        setDragDistance(Math.abs(walk));
        carouselRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = (e: any) => {
        if (dragDistance < CLICK_THRESHOLD) {
            // Esto se ejecuta solo si no hubo un arrastre significativo
            const clickedItemIndex = Math.floor(e.pageX / 500); // Ajusta 300 al ancho de cada ítem
            handleClick(clickedItemIndex);
        }
        setIsDragging(false);
    };

    const handleMouseLeave = () => {
        setIsDragging(false);
    };

    const handleClick = (index: any) => {
        console.log(`Clicked on item ${index}`); // Reemplazá esto con la acción de abrir la imagen
        setShowModal(index);
    };

    return (

        <div className='work__container' >

            <div className="work__id-target" id="work" />

            <div className='work__content-container'>

                <div className='work__title-container utils__fade-in'>

                    <hr />

                    <h1>Trabajo realizado</h1>

                    <hr />

                </div>

                <div className='work__subtitle-container utils__fade-in'>

                    <h1>{workDetail.description}</h1>

                    <div className='work__scroll-container utils__fade-in' >

                        <div className="work__scroll-arrow-go-left">
                            <span></span>
                        </div>

                        <div className="work__scroll-arrow-go-right">
                            <span></span>
                        </div>

                    </div>

                </div>

                <div
                    className="work__list-container utils__fade-in carousel"
                    ref={carouselRef}
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    onMouseLeave={handleMouseLeave}
                    style={{
                        display: 'flex',
                        overflowX: 'hidden',
                        cursor: 'grab',
                    }}
                >

                    <div
                        className='work__list'
                        style={{
                            gridTemplateColumns: `repeat(${workDetail.images.length}, 1fr)`

                        }}
                    >

                        {
                            workDetail.images.map((value, index) => (

                                <button
                                    style={{
                                        background: `url(${value}) center no-repeat`,
                                        backgroundSize: 'cover',
                                        cursor: 'pointer'
                                    }}
                                    className='work__card'
                                    key={value}
                                    onClick={() => {
                                        // handleClick(index);
                                    }}
                                />

                            ))
                        }

                    </div>

                </div>

            </div>

        </div >

    )
}
