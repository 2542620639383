import { useLogEvent } from "../hooks/useLogEvent"

export const Footer = () => {

    const { handleLogEvent } = useLogEvent()

    return (

        <div className='footer__container'>

            <div className="social__id-target" id="social" />

            <div className='social__content-container utils__fade-in'>

                <h1 className='utils__fade-in'>Social</h1>

                <h2 className='utils__fade-in'>Nuestras redes</h2>

                <div className='social__buttons-container utils__fade-in'>

                    <a
                        className='social__button utils__fade-in'
                        href="https://www.facebook.com/FaetaniTechos/"
                        target='_blank'
                        rel="noreferrer"
                        onClick={() => {
                            handleLogEvent('Click_boton_facebook');
                        }}
                    >

                        <img
                            src={require('../images/facebook.webp')}
                            alt='Logo 1'
                        />

                        <h3>Facebook</h3>

                    </a>

                    <a
                        className='social__button utils__fade-in'
                        href="mailto:info@faetanitechos.com.ar"
                        target='_blank'
                        rel="noreferrer"
                        onClick={() => {
                            handleLogEvent('Click_boton_correo');
                        }}
                    >

                        <img
                            src={require('../images/correo-electronico.webp')}
                            alt='Logo 2'
                        />

                        <h3>Correo</h3>

                    </a>

                </div>

            </div>

            <div className='footer__content-container'>

                <div className='footer__buttons-container'>

                    <div className='footer__label-container utils__fade-in'>

                        <img
                            src={require('../images/ubicacion.webp')}
                            alt='Logo 1'
                        />

                        <h3>UBICACIÓN</h3>

                    </div>

                    <a className='footer__button utils__fade-in'
                        href="https://www.google.com/maps?ll=-34.514973,-58.681514&z=16&t=m&hl=es&gl=AR&mapclient=embed&cid=4528629032727864017"
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                            handleLogEvent('Click_boton_ubicacion');
                        }}
                    >

                        <h3>José María Márquez 2472, Villa de Mayo</h3>

                    </a>

                    <div className='footer__label-container utils__fade-in'>

                        <img
                            src={require('../images/telefono.webp')}
                            alt='Logo 1'
                        />

                        <h3>CONTACTOS</h3>

                    </div>

                    <a
                        className='footer__button utils__fade-in'
                        href="tel:+5491168132381"
                        onClick={() => {
                            handleLogEvent('Click_boton_primer_telefono');
                        }}
                    >

                        <h3>+54 011 6813-2381 (Director General)</h3>

                    </a>

                    <a
                        className='footer__button utils__fade-in'
                        href="tel:+5491140550914"
                        onClick={() => {
                            handleLogEvent('Click_boton_segundo_telefono');
                        }}
                    >

                        <h3>+54 011 4055-0914 (Gerente técnico)</h3>

                    </a>
                </div>

                <iframe
                    className="footer__maps utils__fade-in"
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3287.523080834595!2d-58.68637944577468!3d-34.51497320354621!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95bcbd5dc6671c7b%3A0x3ed8ec1c8f43e2d1!2sFaetani%20Techos!5e0!3m2!1ses!2sar!4v1703000901893!5m2!1ses!2sar"
                    loading="lazy" />

            </div >


        </div >

    )
}
