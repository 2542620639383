
export const AppLogoComponent = () => {

    return (

        <div className={`logo__container`}>

            <img
                className='logo__logoImage'
                src={require('../images/faetani-logo-white.webp')}
                alt='logo'
            />

        </div>

    )
}
