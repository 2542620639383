
export const useAnimations = () => {

    const handleClickGoHomeRefresh = () => {
        const element = document.getElementById('home');
        if (element) {
            element.scrollIntoView({ behavior: 'auto' });
        }
    }

    const handleClickGoHome = () => {
        const element = document.getElementById('home');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoServices = () => {
        const element = document.getElementById('services');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoAbout = () => {
        const element = document.getElementById('about');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoSocial = () => {
        const element = document.getElementById('social');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoWork = () => {
        const element = document.getElementById('work');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleClickGoWorkHome = () => {
        const element = document.getElementById('workHome');
        if (element) {
            element.scrollIntoView({ behavior: 'auto' });
        }
    }

    const handleClickGoLastWorks = () => {
        const element = document.getElementById('works');
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }


    return {
        handleClickGoHomeRefresh,
        handleClickGoHome,
        handleClickGoServices,
        handleClickGoAbout,
        handleClickGoSocial,
        handleClickGoWork,
        handleClickGoWorkHome,
        handleClickGoLastWorks
    }

}