import { useEffect, useRef, useState } from "react";

interface Props {
    showModal: boolean
    setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

export const AboutModal = ({ showModal, setShowModal }: Props) => {

    const containerRef = useRef(null);
    const [visibleImage, setVisibleImage] = useState(0);

    const aboutList = [
        {
            id: 0,
            title: 'VISIÓN',
            description: 'Queremos crear y realizar obras y proyectos de cubiertas que contribuyan a mejorar la calidad de vida de las personas, atendiendo sus gustos estéticos, utilizando para ello la expertiz, el talento y profesionalismo de nuestra gente, equipamiento y herramental de última generación, combinado con herramental antiguo, irremplazable al momento de concretar ciertos trabajos específicos artesanos y de restauración. Propendemos la utilización de materiales nobles y de calidad, siempre respetando la seguridad y el cuidado y la protección del medio ambiente.'
        },
        {
            id: 1,
            title: 'MISIÓN',
            description: 'Nuestra misión es ser una empresa sustentable de excelencia y reconocida en el mercado como líderes en nuestro segmento de la industria, que valora y potencia la participación y profesionalismo de sus colaboradores. Formar y trasmitir a las siguiente generaciónes todos los conocimientos acumulados durante años en este arte/oficio, manteniendo los estándares éticos más elevados, a través de una previsión y manejo sistemático del riesgo.'
        },
        {
            id: 2,
            title: 'VALORES',
            description: 'Integridad: Conformamos un equipo de personas éticas y responsables dedicadas a alcanzar sus valores con profesionalismo y transparencia.'
        },
        {
            id: 3,
            title: 'COMPROMISO',
            description: 'Nos comprometemos con la calidad del servicio que brindamos buscando la excelencia en forma permanente. Tenemos un firme compromiso con nuestros clientes y la comunidad, mediante el respeto y cuidado al medio ambiente.'
        },
        {
            id: 4,
            title: 'SEGURIDAD',
            description: 'Creamos un entorno laboral cada vez más seguro para la salud de las personas involucradas en nuestras operaciones.'
        },
        {
            id: 5,
            title: 'DESARROLLO',
            description: 'Fomentamos el desarrollo personal y profesional de nuestros colaboradores.'
        }
    ]

    const [animationActive, setAnimationActive] = useState(false);

    const handleClick = () => {


    };


    useEffect(() => {

        const handleScroll = () => {
            const container: any = containerRef.current;

            if (container) {

                const scrollLeft = container.scrollLeft;
                const children = container.children;

                for (let i = 0; i < children.length; i++) {
                    const child = children[i];
                    const childLeft = child.offsetLeft;
                    const childWidth = child.offsetWidth;

                    if ((scrollLeft >= childLeft && scrollLeft < childLeft + childWidth)) {
                        setVisibleImage(i + 1);
                        break;
                    } else if (scrollLeft === 0) {
                        setVisibleImage(0);
                        break;
                    }
                }
            }
        };

        const container: any = containerRef.current;

        if (container) {

            container.addEventListener('scroll', handleScroll);
            return () => {
                container.removeEventListener('scroll', handleScroll);

            };

        }

    }, []);

    useEffect(() => {

        handleClick()

    }, [showModal]);

    return (

        <div className='aboutModal__container' id="modal">

            <button
                onClick={() => {
                    setShowModal(false)
                }}
            >

                <img
                    className='aboutModal__close-image'
                    src={require('../images/cerrar.webp')}
                    alt='logo'
                    id="cerrarModal"
                />

            </button>


            <div className='aboutModal__works-counter' >

                {
                    aboutList.map((value) => (

                        <div
                            style={{ backgroundColor: value.id === visibleImage ? 'white' : undefined }}
                            key={value.id}
                        />

                    ))
                }

            </div>

            <div ref={containerRef} className='aboutModal__content-container'>

                {
                    aboutList.map((value) => {

                        return (

                            <div
                                className={`aboutModal__info-container ${animationActive ? 'active' : ''}`}
                                key={value.id}
                            >

                                <div className="aboutModal__go-corner" >

                                    <div className="aboutModal__go-arrow">
                                        →
                                    </div>

                                </div>

                                <h1>{value.title}</h1>

                                <h2>{value.description}</h2>

                            </div>

                        )

                    })
                }

            </div>

        </div >

    )
}
