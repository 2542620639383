export const Preloader = () => {

    return (

        <div className={`preloader__container active`} id="modal">

            <div className="preloader__line-top active">

                <span></span>
                <span></span>

            </div>

            <div className='preloader__content-container'>

                <h1 className='logo__logoName preloader__content-text'>Faetani<span>Techos</span></h1>

            </div >

            <div className="preloader__line-bottom active">

                <span></span>
                <span></span>

            </div>


        </div >

    )
}
