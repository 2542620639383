export const Services = () => {

    return (

        <div className='services__container' >

            <div className="services__id-target" id="services" />

            <div className='services__content-container'>

                <div className='services__title-container utils__fade-in'>

                    <hr />

                    <h1>Tenemos una solución para cada necesidad</h1>

                    <hr />

                </div>

                <div className='services__subtitle-container utils__fade-in'>

                    <h1>Nuestros servicios</h1>

                    <div className='services__scroll-container' >

                        <div className="services__scroll-arrow-go-left">
                            <span></span>
                        </div>

                        <div className="services__scroll-arrow-go-right">
                            <span></span>
                        </div>

                    </div>

                </div>

                <div className='services__list-container utils__fade-in'>

                    <div className='services__list'>

                        <div className='services__card'>

                            <div className="first-content">

                                <h2>Construcción</h2>

                                <h1>Techos de tejas</h1>

                                <img
                                    src={require('../images/tocar.webp')}
                                    alt='tocar'
                                />

                            </div>

                            <div className="second-content">

                                <h1>
                                    <li>Francesas</li>
                                    <li>Coloniales</li>
                                    <li>Normandas</li>
                                    <li>Portuguesas</li>
                                    <li>Romanas</li>
                                    <li>Classic</li>
                                </h1>

                            </div>

                        </div>

                        <div className='services__card'>

                            <div className="first-content">


                                <h2>Construcción</h2>

                                <h1>Techos de chapas</h1>

                                <img
                                    src={require('../images/tocar.webp')}
                                    alt='tocar'
                                />

                            </div>

                            <div className="second-content">

                                <h1>
                                    <li>Acanaladas</li>
                                    <li>Trapezoidales</li>
                                    <li>Prepintadas</li>
                                </h1>


                            </div>

                        </div>

                        <div className='services__card'>

                            <div className="first-content">


                                <h2>Construcción</h2>

                                <h1>Techos de pizarras</h1>

                                <img
                                    src={require('../images/tocar.webp')}
                                    alt='tocar'
                                />

                            </div>

                            <div className="second-content">

                                <h1>
                                    <li>Naturales</li>
                                    <li>Eternit</li>
                                    <li>Tecom</li>
                                    <li>Bituminosas</li>
                                </h1>

                            </div>

                        </div>

                        <div className='services__card'>

                            <div className="first-content">

                                <h2>Construcción</h2>

                                <h1>Estructuras de madera</h1>

                                <img
                                    src={require('../images/tocar.webp')}
                                    alt='tocar'
                                />

                            </div>

                            <div className="second-content">

                                <h1 style={{ padding: '0px 10px', textAlign: 'center', lineHeight: 1.7 }}>Realizamos todo tipo de estructuras de madera para techos, cabriadas o cerchas de maderamen compuesto, puentes de 1 a 20 metros de longitud.</h1>

                            </div>

                        </div>

                        <div className='services__card'>

                            <div className="first-content">

                                <h2>Construcción</h2>

                                <h1>Reparaciones</h1>

                                <img
                                    src={require('../images/tocar.webp')}
                                    alt='tocar'
                                />

                            </div>

                            <div className="second-content">

                                <h1 style={{ padding: '0px 10px', textAlign: 'center', lineHeight: 1.7 }}>Realizamos reparaciones de calidad, con garantía y manteniendo siempre el estilo y estética del techo a intervenir.</h1>

                            </div>

                        </div>

                        <div className='services__card'>

                            <div className="first-content">

                                <h2>Construcción</h2>

                                <h1>Zinguerias</h1>

                                <img
                                    src={require('../images/tocar.webp')}
                                    alt='tocar'
                                />

                            </div>

                            <div className="second-content">

                                <h1 style={{ padding: '0px 10px', textAlign: 'center', lineHeight: 1.5 }}>Contamos con un taller con todas las maquinarias. Lo que nos permite fabricar las piezas de zinguería necesarias y a medida, según el proyecto de la cubierta a intervenir.</h1>


                            </div>

                        </div>

                        <div className='services__card'>

                            <div className="first-content">

                                <h2>Construcción</h2>

                                <h1>Mantenimientos industriales</h1>

                                <img
                                    src={require('../images/tocar.webp')}
                                    alt='tocar'
                                />

                            </div>

                            <div className="second-content">

                                <h1 style={{ padding: '0px 10px', textAlign: 'center', lineHeight: 1.5 }}>Nuestros equipos de trabajo capacitados para acompañar a la industria en su producción, cuentan con los más altos estándares en seguridad industrial.</h1>

                            </div>

                        </div>

                        <div className='services__card'>

                            <div className="first-content">

                                <h2>Construcción</h2>

                                <h1>Restauraciones</h1>

                                <img
                                    src={require('../images/tocar.webp')}
                                    alt='tocar'
                                />

                            </div>

                            <div className="second-content">

                                <h1 style={{ padding: '0px 10px', textAlign: 'center', lineHeight: 1.7 }}>Nuestra experiencia en este arte nos permite asesorar a nuestros clientes en su proyecto y realizar el trabajo con técnicas antiguas.</h1>

                            </div>

                        </div>

                    </div>

                </div>

            </div>

        </div >

    )
}
