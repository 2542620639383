import { useEffect, useState } from "react"
import { Header } from "../components/Header"
import { Proyect } from "../components/Proyect"
import { Work } from "../components/Work"
import { WsFloatButton } from "../components/WsFloatButton"
import { scrollFadeIn } from "../effects/Observers"
import { WorkModal } from "../components/WorkModal"

export const ProyectScreen = () => {

    const [showModal, setShowModal] = useState<number | null>(null);

    useEffect(() => {
        scrollFadeIn()
    }, [])

    return (

        <div className='home__main-content'>

            <WsFloatButton />

            {showModal !== null && <WorkModal showModal={showModal} setShowModal={setShowModal} />}

            <Header />

            <Proyect />

            <Work setShowModal={setShowModal} />

        </div>

    )
}
