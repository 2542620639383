import { useHistory } from "react-router-dom"
import { useLogEvent } from "../hooks/useLogEvent"
import { useEffect, useRef, useState } from "react";
import { worksList } from "../utils/Jsons";
import { WorkProps } from "../interfaces/WorkInterfaces";

export const Proyects = () => {

    const history: any = useHistory();

    const { handleLogEvent } = useLogEvent()

    const containerRef = useRef(null);

    const [visibleImage, setVisibleImage] = useState(history.location.state?.workIndex ?? 0);

    useEffect(() => {

        handleClick()

        const container: any = containerRef.current;
        const nuevaPosicion = container.children[history.location.state?.workIndex ?? 0].offsetLeft;
        container.scrollTo({ left: nuevaPosicion, behavior: 'auto' });
        setVisibleImage(history.location.state?.workIndex ?? 0);

    }, [])

    const [animationActive, setAnimationActive] = useState(false);

    const handleClick = () => {

        setAnimationActive(true);

        setTimeout(() => {
            setAnimationActive(false);
        }, 1500);
    };

    const cambiarImagen = (direction: any) => {

        handleClick()

        const container: any = containerRef.current;
        const totalImagenes = worksList.length;

        let nuevaImagenVisible = visibleImage + direction;

        if (nuevaImagenVisible === -1) {
            nuevaImagenVisible = totalImagenes - 1;
        } else if (nuevaImagenVisible < 1) {
            nuevaImagenVisible = 0;
        } else if (nuevaImagenVisible >= totalImagenes) {
            nuevaImagenVisible = 0;
        }

        const nuevaPosicion = container.children[nuevaImagenVisible].offsetLeft;
        container.scrollTo({ left: nuevaPosicion, behavior: 'smooth' });
        setVisibleImage(nuevaImagenVisible);
    };

    const handleGoProyect = (work: WorkProps) => {

        history.push(`/proyects/${work.id}`, { work, returnTo: 'works' })

        handleLogEvent(`Click_obra_${work.event}`);

    }

    return (

        <div className='proyects__container' id="home">

            <div className='proyects__scroll-container' >

                <button disabled={animationActive} className="proyects__scroll-arrow-go-left" onClick={() => cambiarImagen(-1)}>
                    <span></span>
                </button>

                <button disabled={animationActive} className="proyects__scroll-arrow-go-right" onClick={() => cambiarImagen(1)}>
                    <span></span>
                </button>

            </div>

            <div className='proyects__works-counter' >

                {
                    worksList.map((value) => (

                        <div
                            style={{ backgroundColor: value.id === (visibleImage + 1) ? 'white' : undefined }}
                            className={`proyects__work-indicator ${value.id === (visibleImage + 1) ? 'proyects__work-indicator-selected' : ''}`}
                            key={value.id}
                        />

                    ))
                }

            </div>

            <div ref={containerRef} className='proyects__content-container' id="proyects-list">

                {
                    worksList.map((value) => (

                        <div
                            key={value.id}
                            style={{
                                background: `#fff url(${value.images[0]}) center no-repeat`,
                                backgroundSize: 'cover',
                            }}
                            className='proyects__image'
                        />

                    ))
                }

                {
                    worksList.map((value) => (

                        <div className={`proyects__info ${animationActive ? 'active' : ''}`} key={value.id}>

                            <h2>Obras</h2>

                            <h3>Localización: <span>{worksList[visibleImage].address}</span></h3>

                            <h1>"{worksList[visibleImage].title}"</h1>

                            <button onClick={() => {
                                handleGoProyect(worksList[visibleImage]);
                            }}>VER MÁS</button>

                        </div>

                    ))
                }


            </div>

        </div>

    )
}
