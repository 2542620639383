import { worksList } from "../utils/Jsons"
import { useHistory } from "react-router-dom"
import { useAnimations } from "../effects/Effects";
import { useLogEvent } from "../hooks/useLogEvent";

export const Works = () => {

    const history = useHistory();

    const { handleClickGoWorkHome } = useAnimations()

    const { handleLogEvent } = useLogEvent()

    return (

        <div className='works__container' >

            <div className="works__id-target" id="works" />

            <div className='works__content-container'>

                <div className='works__title-container utils__fade-in'>

                    <hr />

                    <h1>Nuestras últimas obras</h1>

                    <hr />

                    <div className='works__scroll-container utils__fade-in' >

                        <div className="works__scroll-arrow-go-left">
                            <span></span>
                        </div>

                        <div className="works__scroll-arrow-go-right">
                            <span></span>
                        </div>

                    </div>

                </div>

                <div className='works__list-container'>

                    <div
                        className='works__list '
                        style={{
                            gridTemplateColumns: `repeat(${worksList.slice(0, 3).length}, 1fr)`,
                        }}
                    >

                        {
                            worksList.slice(0, 3).map((work) => (

                                <button
                                    className='works__card'
                                    style={{
                                        background: `url(${work.images[0]}) center no-repeat`,
                                        backgroundSize: 'cover'
                                    }}
                                    key={work.id}
                                    onClick={async () => {

                                        await history.push(`/proyects/${work.id}`, { work, returnTo: 'home' });

                                        await handleClickGoWorkHome()

                                        handleLogEvent(`Click_obra_${work.event}`);

                                    }}
                                >

                                    <div className='works__second-card'>

                                        <h1>{work.title}</h1>

                                        <h2>{work.address}</h2>

                                    </div>

                                    <div className='card-content'>

                                        <div>VER MÁS</div>

                                    </div>

                                </button>
                            ))
                        }

                    </div>

                </div>

            </div>

        </div >

    )
}
